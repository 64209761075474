import React from 'react'
import type { AppProps } from 'next/app'
import { ToastProvider, ToastViewport } from '@tamagui/toast'
import AntDesignConfigProvider from '@centrito/app/provider/antdesign'
import AuthProvider from '@centrito/app/provider/auth'
import PortalProvider from '@centrito/app/provider/portal'
import ReduxProvider from '@centrito/app/provider/redux'
import Route from '@centrito/app/provider/route'
import RouteProvider from '@centrito/app/provider/router'
import { BaseToast } from '@centrito/ui/src/components/BaseToast'
import NetInfoProvider from './netinfo'
import EnablePosthogProvider from './posthog'
import SupabaseProvider from './supabase'
import ThemeProvider from './theme'
import { TRPCProvider } from './trpc'
import UrlProvider from './url'

const Provider: React.FC<
  AppProps & {
    children: React.ReactNode
  }
> = ({ children, ...appProps }) => {
  return (
    <SupabaseProvider {...appProps}>
      <TRPCProvider>
        <ThemeProvider {...appProps}>
          <ToastProvider>
            <NetInfoProvider>
              <ReduxProvider>
                <AntDesignConfigProvider>
                  <AuthProvider>
                    <EnablePosthogProvider>
                      <PortalProvider>
                        <RouteProvider>
                          <Route>
                            <UrlProvider>{children}</UrlProvider>
                          </Route>
                          <BaseToast duration={1500} />
                        </RouteProvider>
                      </PortalProvider>
                    </EnablePosthogProvider>
                  </AuthProvider>
                </AntDesignConfigProvider>
              </ReduxProvider>
              <ToastViewport
                bottom={120}
                justifyContent="center"
                display="flex"
                alignSelf="center"
                multipleToasts
              />
            </NetInfoProvider>
          </ToastProvider>
        </ThemeProvider>
      </TRPCProvider>
    </SupabaseProvider>
  )
}

export default Provider

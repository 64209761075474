import React from 'react'
import { type TextProps } from 'tamagui'
import { DefaultTextPrimary } from './DefaultTextPrimary'

export const SemiBoldTextPrimary: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <DefaultTextPrimary fontWeight="600" {...rest}>
      {children}
    </DefaultTextPrimary>
  )
}

import isNil from 'lodash.isnil'
import type { User } from '@centrito/api/shared/types'
import type { AuthenticatedData } from '@centrito/app/store/slices/auth/schema'

export const getAuthenticatedData = (user: User): AuthenticatedData | null => {
  return isNil(user.phone)
    ? null
    : {
        userId: user.id,
        phone: user.phone,
        isRegistered: user.user_metadata.isRegistered,
        role: user.user_metadata.role,
      }
}

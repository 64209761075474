import { z } from 'zod'

export const AuthenticatedData = z.object({
  userId: z.string(),
  isRegistered: z.boolean(),
  phone: z.string().optional(),
  role: z.array(z.string()),
})
export type AuthenticatedData = z.infer<typeof AuthenticatedData>

export const AuthState = z.object({
  authenticatedData: AuthenticatedData.nullable(),
  authLoaded: z.boolean(),
})
export type AuthState = z.infer<typeof AuthState>

import React from 'react'
import { Linking } from 'react-native'
import { Stack, XStack } from '@centrito/ui/src'
import { Button } from '@centrito/ui/src'
import { DisplayText1 } from '@centrito/ui/src/components/text/new'
import { FootnoteStandardText } from '@centrito/ui/src/components/text/new'
import {
  InstagramIcon,
  SamSamCloud,
  SamSamLogo,
  TikTokIcon,
  WhatsAppHelpButton,
} from './components'

export const ShopperDesktopBackground: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <XStack
      width="100%"
      height="100%"
      overflow="hidden"
      top={0}
      left={0}
      backgroundColor="$secondary400"
      justifyContent="center"
    >
      <Stack
        position="absolute"
        top={20}
        left={30}
        $platform-web={{
          width: 'fit-content',
        }}
        $md={{
          display: 'none',
        }}
      >
        <SamSamLogo />
      </Stack>

      <Stack
        position="absolute"
        left={30}
        bottom={20}
        $platform-web={{
          width: 'fit-content',
        }}
        $md={{
          display: 'none',
        }}
      >
        <FootnoteStandardText fontSize={12} color="$pureWhite">
          Más simple, más confiable.
        </FootnoteStandardText>
        <FootnoteStandardText fontSize={12} color="$pureWhite">
          Samsam ®
        </FootnoteStandardText>
      </Stack>

      <Stack
        position="absolute"
        right={30}
        top={20}
        $platform-web={{
          width: 'fit-content',
        }}
        $md={{
          display: 'none',
        }}
      >
        <WhatsAppHelpButton />
      </Stack>

      <XStack
        position="absolute"
        right={30}
        bottom={20}
        $platform-web={{
          width: 'fit-content',
        }}
        $md={{
          display: 'none',
        }}
        gap={7}
        alignItems="flex-start"
      >
        <DisplayText1 color="#FFF6EE" fontSize={14}>
          ¡Síguenos en redes!
        </DisplayText1>

        <XStack gap={10}>
          <Button
            unstyled
            padding={0}
            backgroundColor="transparent"
            borderWidth={0}
            icon={InstagramIcon}
            onPress={(): Promise<void> => Linking.openURL('https://instagram.com/samsam_col')}
          />
          <Button
            unstyled
            padding={0}
            backgroundColor="transparent"
            borderWidth={0}
            icon={TikTokIcon}
            onPress={(): Promise<void> => Linking.openURL('https://www.tiktok.com/@samsam.oficial')}
          />
        </XStack>
      </XStack>

      <Stack
        position="absolute"
        left={0}
        top={70}
        $platform-web={{
          width: 'fit-content',
        }}
      >
        <SamSamCloud />
      </Stack>

      <Stack
        position="absolute"
        right={0}
        bottom={90}
        $platform-web={{
          width: 'fit-content',
          transform: 'scaleX(-1)',
        }}
      >
        <SamSamCloud />
      </Stack>
      {children}
    </XStack>
  )
}

import type { CartItemBasic } from '@centrito/api/models/public'
import type { CartItemExtendedPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites/cart-item-extended.composite'

function getCartItemBasic<T extends CartItemExtendedPublicComposite>(cartItem: T): CartItemBasic {
  return {
    productId: cartItem.product.product.id,
    productVariantId: cartItem.productVariant.variant.id,
    sizeName: cartItem.cartItem.productSize,
    quantity: cartItem.cartItem.quantity,
  }
}

export default getCartItemBasic

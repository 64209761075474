import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import type { UserProfile } from '@centrito/api/models/public'
import type { UserProfileCreatePayload } from '@centrito/api/services/auth/private/createUserProfile'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'
import { loadAnonymousCart, loadUserData } from '@centrito/app/store/slices/userData/thunks'
import posthogClient from '@centrito/app/utils/services/analytics/posthog'

const signUp = createAsyncThunk<
  { userProfile: UserProfile },
  UserProfileCreatePayload,
  AppThunkApiConfig
>(`${REDUCER_NAMES.AUTH}/signUp`, async (payload, thunkAPI) => {
  try {
    const userProfile = await trpcProxyClient.auth.customer.signUp.mutate(payload)
    thunkAPI.dispatch(loadUserData())
    thunkAPI.dispatch(loadAnonymousCart())
    posthogClient.captureCustomEvent('shopper_new_registration')
    return { userProfile }
  } catch (error) {
    return thunkAPI.rejectWithValue(miniSerializeError(error))
  }
})

export default signUp

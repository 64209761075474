import { z } from 'zod'
import type { ProductExtendedAggregate } from '@centrito/api/nest/catalog/products/domain/aggregates/product-extended.aggregate'
import type { GetProductsFilterType as FilterType } from '@centrito/api/shared/enums'

export type GetProductsPageQueryParam = {
  p?: string | string[]
}

export type GetProductsFiltersQueryParam = {
  f?: string | string[]
}

export interface GetProductsQueryParams
  extends GetProductsPageQueryParam,
    GetProductsFiltersQueryParam {}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace GetProducts {
  export type FilterValue = {
    [FilterType.BRAND_ID]: string[]
    [FilterType.CATEGORY]: string[]
    [FilterType.COLOR]: string[]
    [FilterType.SIZE]: string[]
    [FilterType.CATEGORY_PREFIX]: string
    [FilterType.LIST_ID]: string
    [FilterType.PRICE_RANGE]: { low: number; high: number } // TODO: Change to Number
    [FilterType.SEARCH_TERM]: string
    [FilterType.SUPPLIER]: string[]
  }
  export type FilterItemValue = {
    [FilterType.BRAND_ID]: string
    [FilterType.CATEGORY]: string
    [FilterType.COLOR]: string
    [FilterType.SIZE]: string
    [FilterType.CATEGORY_PREFIX]: string
    [FilterType.LIST_ID]: string
    [FilterType.PRICE_RANGE]: { low: number; high: number }
    [FilterType.SEARCH_TERM]: string
    [FilterType.SUPPLIER]: string
  }
  export type Filters = {
    [FilterType.BRAND_ID]?: FilterValue[FilterType.BRAND_ID]
    [FilterType.CATEGORY]?: FilterValue[FilterType.CATEGORY]
    [FilterType.COLOR]?: FilterValue[FilterType.COLOR]
    [FilterType.SIZE]?: FilterValue[FilterType.SIZE]
    [FilterType.CATEGORY_PREFIX]?: FilterValue[FilterType.CATEGORY_PREFIX]
    [FilterType.LIST_ID]?: FilterValue[FilterType.LIST_ID]
    [FilterType.PRICE_RANGE]?: FilterValue[FilterType.PRICE_RANGE]
    [FilterType.SEARCH_TERM]?: FilterValue[FilterType.SEARCH_TERM]
    [FilterType.SUPPLIER]?: FilterValue[FilterType.SUPPLIER]
  }
  export type FilterItem<K extends FilterType = FilterType, T = FilterItemValue[K]> = {
    type: FilterType
    value: T
  }
  export type QueryParams = GetProductsQueryParams
  export type Query = {
    page?: number
    filters?: Filters
  }
  export type Response = {
    products: ProductExtendedAggregate[]
    count: number
  }
  export type FiltersQueryParam = {
    f?: string | string[]
  }
}

export const ShapedGroup = z.enum(['A', 'B'])
export type ShapedGroupType = z.infer<typeof ShapedGroup>

import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import { type SupabaseClient } from '@supabase/supabase-js'
import Sentry from '@centrito/app/sentry/shopper'
import { type AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'
import { shopperNotificationsClient } from '@centrito/app/utils/notifications/shopper'
import posthogClient from '@centrito/app/utils/services/analytics/posthog'

interface SignOutRequestPayload {
  supabaseClient: SupabaseClient
}

const signOut = createAsyncThunk<void, SignOutRequestPayload, AppThunkApiConfig>(
  `${REDUCER_NAMES.AUTH}/signOut`,
  async (payload, thunkAPI) => {
    try {
      if (shopperNotificationsClient.getIsInitialised()) {
        try {
          await shopperNotificationsClient.logout()
        } catch (error) {
          Sentry.captureException(error)
        }
      } else {
        shopperNotificationsClient.initialise()
      }
      const { supabaseClient } = payload
      const response = await supabaseClient.auth.signOut({ scope: 'local' })
      if (response.error) throw response.error

      posthogClient.resetIdentity()

      return
    } catch (error) {
      return thunkAPI.rejectWithValue(miniSerializeError(error))
    }
  },
)
export default signOut

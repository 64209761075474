import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import isNil from 'lodash.isnil'
import isUndefined from 'lodash.isundefined'
// In order to use uuidv4 we need to import 'react-native-get-random-values' for it to work on mobile
import 'react-native-get-random-values'
import { v4 as uuidv4 } from 'uuid'
import type { CartItemTarget } from '@centrito/api/models/public'
import type { CartExtendedPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites/cart-extended.composite'
import type { CartItemExtendedPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites/cart-item-extended.composite'
import type { UpdateCartItemsPayload } from '@centrito/api/router/user/cart/computeCartItemPricing'
import findCartItem from '@centrito/api/services/shopper/cart/items/utils/findCartItem'
import {
  removeCartItemLocal,
  updateCartItemQuantityLocal,
} from '@centrito/api/services/shopper/cart/utils/modifyLocalCart'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'
import metaClient from '@centrito/app/utils/services/analytics/meta'
import posthogClient from '@centrito/app/utils/services/analytics/posthog'

export interface UpdateCartItemsResponse {
  updatedCart: CartExtendedPublicComposite
}

const getUpdatedLocalCartItems = async (
  cart: CartExtendedPublicComposite,
  targetItem: CartItemTarget,
  payload: UpdateCartItemsPayload,
): Promise<CartItemExtendedPublicComposite[]> => {
  const { product, productVariant, productPresentation, quantityChange, isDeleteItem } = payload
  const cartItemPricingData = await trpcProxyClient.user.cart.computeCartItemPricing.mutate(payload)
  const existingCartItem = isNil(cart)
    ? undefined
    : findCartItem<CartItemExtendedPublicComposite>(cart.items, targetItem)

  if (!isUndefined(existingCartItem) && !isNil(cart)) {
    const newQuantity = existingCartItem.cartItem.quantity + quantityChange
    if (newQuantity <= 0 || isDeleteItem) {
      return removeCartItemLocal(cart.items, existingCartItem)
    } else {
      return updateCartItemQuantityLocal(cart.items, existingCartItem, quantityChange)
    }
  } else if (quantityChange > 0) {
    const selectedPresentation = productVariant.variant.data.sizes.find(
      (size) => size.name === productPresentation.name,
    )
    if (isNil(selectedPresentation)) {
      throw new Error('Error: cant find cartitem presentation, should not happen')
    }

    // Create new cart item
    const newCartItem = {
      product,
      productVariant,
      cartItem: {
        id: uuidv4(),
        productSize: productPresentation.name,
        quantity: quantityChange,
        productPresentation: selectedPresentation,
      },
      pricingData: cartItemPricingData,
    } satisfies CartItemExtendedPublicComposite
    return isNil(cart?.items) ? [newCartItem] : cart?.items.concat([newCartItem])
  } else {
    return cart?.items ?? []
  }
}

const updateCartItem = createAsyncThunk<
  UpdateCartItemsResponse,
  UpdateCartItemsPayload,
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/updateCartItem`, async (payload, thunkAPI) => {
  try {
    const isAuthenticated = !isUndefined(thunkAPI.getState().auth.authenticatedData?.userId)
    const phoneNumber = thunkAPI.getState().auth.authenticatedData?.phone
    const cart = thunkAPI.getState().userData.cart
    const {
      product,
      productVariant,
      productPresentation,
      quantityChange,
      pricingData,
      isDeleteItem,
      cartCampaignRules,
    } = payload
    const targetItem = {
      productId: product.product.id,
      productVariantId: productVariant.variant.id,
      sizeName: productPresentation.name,
      priceDiscountItem: pricingData.priceItemsDiscount,
    }
    if (!isNil(phoneNumber)) {
      metaClient.captureCustomEvent('shopper_update_cart_items', { ph: phoneNumber })
    } else {
      metaClient.captureCustomEvent('shopper_update_cart_items')
    }
    posthogClient.captureCustomEvent('shopper_update_cart_items', { quantityChange })

    if (isAuthenticated) {
      const { newCart } = await trpcProxyClient.user.cart.updateCartItem.mutate({
        targetItem,
        quantityChange,
        isDeleteItem,
        cartCampaignRules,
      })
      const pricingData = await trpcProxyClient.user.cart.computeCartPricing.mutate(newCart)

      return {
        updatedCart: { ...newCart, pricingData },
      }
    } else {
      const items = await getUpdatedLocalCartItems(cart, targetItem, payload)

      const updatedCart = {
        ...cart,
        items,
        discountData: cartCampaignRules,
      }
      const pricingData = await trpcProxyClient.user.cart.computeCartPricing.mutate(updatedCart)
      return {
        updatedCart: {
          ...updatedCart,
          pricingData,
        },
      }
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(miniSerializeError(error))
  }
})
export default updateCartItem

import type { CartItemTarget } from '@centrito/api/models/public'
import type { CartItemExtendedPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites/cart-item-extended.composite'

function findCartItem<T extends CartItemExtendedPublicComposite>(
  cartItems: T[],
  targetItem: CartItemTarget,
  onHasDuplicateItemsError?: () => void,
): T | undefined {
  const targetItemList = cartItems.filter((cartItem) => {
    return (
      cartItem.product?.product.id === targetItem.productId &&
      cartItem.productVariant?.variant.id === targetItem.productVariantId &&
      cartItem.cartItem.productSize === targetItem.sizeName
    )
  })
  if (targetItemList.length > 1) {
    if (onHasDuplicateItemsError) onHasDuplicateItemsError()
  }
  return targetItemList?.[0]
}

export default findCartItem

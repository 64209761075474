import React, { useEffect } from 'react'
import { type NextPage } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { trpc } from '@centrito/app/api/trpc/index.web'
import { ErrorPage } from '@centrito/app/components/ErrorPage'
import { ShopperDesktopBackground } from '@centrito/app/components/ShopperDesktopBackground'
import {
  DESCRIPTION,
  FACEBOOK_PIXEL_SCRIPT_JS,
  META_CONVERSION_PIXEL_ID,
  ROOT_URL,
  TIKTOK_PIXEL_SCRIPT_JS,
  TITLE,
} from '@centrito/app/config'
import Provider from '@centrito/app/provider'
import PaginationProvider from '@centrito/app/provider/pagination'
import { shopperNotificationsClient } from '@centrito/app/utils/notifications/shopper'
import ReactNativeNextJsScrollRestore from '@centrito/app/utils/scrollRestore'

require('../styles/styles.less')

interface MetaTagsGeneratorSEOProps {
  title?: string
  description?: string
  urlPath?: string
  urlImage?: string
}

const MetaTagsGeneratorSEO: NextPage<MetaTagsGeneratorSEOProps> = ({
  title,
  description,
  urlPath,
  urlImage,
}) => {
  const finalTitle = `${title || TITLE} | SamSam`
  const finalDescription = description || DESCRIPTION
  const finalUrlPath = urlPath || '/'
  const finalUrlImage = urlImage || `${ROOT_URL}/assets/images/logos/samsam_logo_blue.png`

  return (
    <Head>
      <title>{finalTitle}</title>
      <meta name="title" content={finalTitle} key="title" />
      <meta name="description" content={finalDescription} key="description" />
      {urlPath && <link rel="canonical" href={`${ROOT_URL}${finalUrlPath}`} key="canon" />}

      {/* Facebook meta tags */}
      <meta property="og:type" content="website" key="og:type" />
      <meta key="og:url" property="og:url" content={`${ROOT_URL}${finalUrlPath}`} />
      <meta property="og:title" content={finalTitle} key="og:title" />
      <meta property="og:description" content={finalDescription} key="og:description" />
      <meta property="og:image" content={finalUrlImage} key="og:image" />
      <meta property="fb:app_id" content={META_CONVERSION_PIXEL_ID} key="fb:app_id" />

      {/* Twitter meta tags */}
      <meta property="twitter:card" content="summary_large_image" key="twitter:card" />
      <meta property="twitter:url" content={`${ROOT_URL}${finalUrlPath}`} key="twitter:url" />
      <meta property="twitter:title" content={finalTitle} key="twitter:title" />
      <meta property="twitter:description" content={finalDescription} key="twitter:description" />
      <meta property="twitter:image" content={finalUrlImage} key="twitter:image" />

      {/* Pushwoosh package json */}
      <link rel="manifest" href={`${ROOT_URL}/manifest.json`} />

      <meta
        httpEquiv="X-UA-Compatible"
        content="IE=edge, width=device-width, initial-scale=1, maximum-scale=1"
        name="viewport"
      />
    </Head>
  )
}

class ErrorBoundary extends React.Component<{ children: React.ReactNode }> {
  state = { hasError: false, error: null }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage statusCode={500} />
    }

    return this.props.children
  }
}

const CustomApp = (appProps: AppProps): JSX.Element => {
  const { Component, pageProps } = appProps
  const { SEOProperties } = pageProps
  useEffect(() => {
    const unsubscribe = ReactNativeNextJsScrollRestore.initialize()

    return unsubscribe
  }, [])

  return (
    <>
      <MetaTagsGeneratorSEO
        title={SEOProperties?.title}
        description={SEOProperties?.description}
        urlImage={SEOProperties?.urlImage}
        urlPath={SEOProperties?.urlPath}
      />
      <noscript>
        {/* eslint-disable @next/next/no-img-element */}
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src={`https://www.facebook.com/tr?id=${META_CONVERSION_PIXEL_ID}&ev=PageView&noscript=1`}
          alt="Facebook Pixel"
        />
      </noscript>
      <Script
        id="load-puswoosh-web-notifications"
        strategy="lazyOnload"
        src="https://cdn.pushwoosh.com/webpush/v3/pushwoosh-web-notifications.js"
        async
        onReady={(): void => shopperNotificationsClient.initialise()}
      />
      <Script id="load-facebook-pixel" strategy="lazyOnload">
        {FACEBOOK_PIXEL_SCRIPT_JS}
      </Script>
      <Script id="load-tiktok-pixel" strategy="lazyOnload">
        {TIKTOK_PIXEL_SCRIPT_JS}
      </Script>
      <ErrorBoundary>
        <PaginationProvider>
          <PostHogProvider client={posthog}>
            <Provider {...appProps}>
              <ShopperDesktopBackground>
                <Component {...pageProps} />
              </ShopperDesktopBackground>
            </Provider>
          </PostHogProvider>
        </PaginationProvider>
      </ErrorBoundary>
    </>
  )
}

// https://nextjs.org/docs/basic-features/typescript#custom-app
// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext: AppContext) => {
//   // calls page's `getInitialProps` d fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//   return { ...appProps }
// }

export default trpc.withTRPC(CustomApp)

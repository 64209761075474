import React from 'react'
import { type FlexAlignType } from 'react-native'
import { Linking } from 'react-native'
import { Spacer, XStack } from 'tamagui'
import { BaseButton } from '@centrito/ui/src/components/button/BaseButton'
import WhatsAppIcon from '@centrito/ui/src/components/svgs/logos/WhatsApp'
import { SemiBoldTextPrimary } from '@centrito/ui/src/components/text'
import { HELP_BUTTON_WHATSAPP_NUMBER } from '@centrito/ui/src/config'

const WA_LINK = `https://wa.me/${HELP_BUTTON_WHATSAPP_NUMBER}?text=Hola,%20necesito%20ayuda%20con%20la%20app%20de%20*Samsam*%20`

export interface HelpMainButtonProps {
  hasNoMargins?: boolean
  alignSelf?: 'auto' | FlexAlignType
}

export const HelpMainButton: React.FC<HelpMainButtonProps> = ({
  hasNoMargins = false,
  alignSelf = 'flex-end',
}) => (
  <XStack
    marginRight={hasNoMargins ? 0 : 10}
    marginTop={hasNoMargins ? 0 : 8}
    alignSelf={alignSelf}
  >
    <BaseButton
      backgroundColor="#18AE2F"
      height={30}
      borderRadius={12}
      rel="noreferrer"
      target="_blank"
      width={90}
      justifyContent="center"
      hoverStyle={{ backgroundColor: '#0E6A1B' }}
      pressStyle={{ backgroundColor: '#0E6A1B' }}
      onPress={(): Promise<void> => Linking.openURL(WA_LINK)}
    >
      <XStack alignItems="center" justifyContent="center" margin={2}>
        <Spacer size={8} />
        <SemiBoldTextPrimary color="#FFFFFF" letterSpacing={2} fontSize={10}>
          AYUDA
        </SemiBoldTextPrimary>
        <Spacer size={4} />
        <WhatsAppIcon color="#fff" width={16} height={16} />
      </XStack>
    </BaseButton>
  </XStack>
)

import { createAsyncThunk } from '@reduxjs/toolkit'
import isUndefined from 'lodash.isundefined'
import type { CartExtendedPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites/cart-extended.composite'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'

const revalidateCoupon = createAsyncThunk<
  {
    updatedCart: CartExtendedPublicComposite
    isDeactivatedCoupon: boolean
  },
  {
    campaignId: string
  },
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/revalidateCoupon`, async (payload, thunkAPI) => {
  const currentState = thunkAPI.getState()
  const isAuthenticated = !isUndefined(currentState.auth.authenticatedData?.userId)
  if (isAuthenticated) {
    const { newCart: updatedCart, isDeactivatedCoupon } =
      await trpcProxyClient.user.cart.refreshDiscountData.mutate()
    return { updatedCart, isDeactivatedCoupon }
  } else {
    throw new Error('User not authenticated')
  }
})
export default revalidateCoupon

import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import isNil from 'lodash.isnil'
import isUndefined from 'lodash.isundefined'
import type { CartExtendedPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites/cart-extended.composite'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'

export interface GetUserCartResponse {
  updatedCart: CartExtendedPublicComposite
}

const getUserCart = createAsyncThunk<GetUserCartResponse, void, AppThunkApiConfig>(
  `${REDUCER_NAMES.USER_DATA}/getUserCart`,
  async (payload, thunkAPI) => {
    try {
      const isAuthenticated = !isUndefined(thunkAPI.getState().auth.authenticatedData?.userId)

      if (!isAuthenticated) {
        throw Error('No permitido. Esta operación solo se puede realizar autenticado')
      }

      const newCart = await trpcProxyClient.user.cart.get.query()

      // if the cart has no coupon, just return the cart
      if (isNil(newCart.cart.discountData)) {
        return {
          updatedCart: newCart,
        }
      }

      const { newCart: updatedCart } = await trpcProxyClient.user.cart.refreshDiscountData.mutate()

      return {
        updatedCart,
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(miniSerializeError(error))
    }
  },
)

export default getUserCart

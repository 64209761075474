import type { User } from '@supabase/supabase-js'
import isUndefined from 'lodash.isundefined'
import { UserRole } from '@centrito/db'

export const AUTH_FLOWS = {
  AUTH: 'Quiero entrar a Centrito!',
  CHECKOUT: 'Quiero ingresar a Centrito!',
}

export const LOGIN_CONTROL_NUMBER = '573006006131'
export const OTP_BUCKET = 'one-time-passkeys'

export const hasProperMetadata = (userMetadata: any): boolean => {
  return !isUndefined(userMetadata?.isRegistered)
}

export const validateMerchantUserData = (user: User): boolean => {
  return !isUndefined(user.email) && hasProperMetadata(user.user_metadata)
}

export const getIsMerchant = (user: User): boolean => {
  return (
    !isUndefined(user.phone) &&
    user.phone.length > 0 &&
    hasProperMetadata(user.user_metadata) &&
    (user.user_metadata.role?.includes(UserRole.MERCHANT) ?? false)
  )
}

export const getIsRegistered = (user: User): boolean => {
  return user.user_metadata?.isRegistered === true
}

export const getIsCustomer = (user: User): boolean => {
  return !getIsMerchant(user) && hasProperMetadata(user.user_metadata)
}

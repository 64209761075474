import isUndefined from 'lodash.isundefined'
import posthog from 'posthog-js'
import { type PosthogClient } from '@centrito/app/utils/services/analytics/posthog'
import type { CustomEventProperties } from './common'

export default function (
  this: PosthogClient,
  eventName: string,
  customEventProperties?: CustomEventProperties,
  isEnabled: boolean = this.analyticsEnabled,
): void {
  if (!isEnabled || !this.clientEnabled) return
  switch (eventName) {
    case 'shopper_order_created':
      posthog.capture(eventName, {
        $set: {
          hasOrdered: true,
        },
      })
      return

    case 'shopper_new_registration':
      posthog.capture(eventName, { $set: { hasOrdered: false } })
      return

    case 'shopper_update_cart_items':
      const quantityChange = customEventProperties?.quantityChange
      if (isUndefined(quantityChange)) return
      posthog.capture(eventName, {
        quantityChange: quantityChange > 0 ? '1' : '-1',
      })
      return

    case 'shopper_delivery_type':
      const deliveryType = customEventProperties?.isPickupStore ? 'pickup_store' : 'home_or_office'
      posthog.capture(eventName, { deliveryType })
      return

    case 'shopper_hamburger_menu_open':
      posthog.capture(eventName)
      return

    case 'shopper_hamburger_menu_close':
      posthog.capture(eventName)
      return

    case 'shopper_pageview_checkout_cart':
      posthog.capture(eventName)
      return

    case 'shopper_checkout_allied_store_updated':
      posthog.capture(eventName)
      return

    case 'shopper_checkout_domicile_updated':
      posthog.capture(eventName)
      return

    case 'shopper_pageview_checkout_payment_nequi':
      posthog.capture(eventName)
      return

    case 'shopper_pageview_checkout_payment_nequi_notification':
      posthog.capture(eventName)
      return

    case 'shopper_pageview_checkout_payment_nequi_nopayment':
      posthog.capture(eventName)
      return

    case 'shopper_pageview_checkout_login':
      posthog.capture(eventName)
      return

    case 'shopper_pageview_checkout_register':
      posthog.capture(eventName)
      return

    case 'shopper_pageview_auth_login':
      posthog.capture(eventName)
      return

    case 'shopper_pageview_auth_register':
      posthog.capture(eventName)
      return

    case 'shopper_product_page_add_to_cart':
      posthog.capture(eventName)
      return

    case 'shopper_download_popup_button_pressed':
      posthog.capture(eventName)
      return

    case 'shopper_download_popup_closed':
      posthog.capture(eventName)
      return

    case 'shopper_pageview_checkout_single':
      posthog.capture(eventName)
      return

    case 'shopper_download_header_button_pressed':
      posthog.capture(eventName)
      return

    case 'shopper_download_header_closed':
      posthog.capture(eventName)
      return

    case 'shopper_web_product_list_tab_bar_press':
      const tabName = customEventProperties?.productListTabName
      posthog.capture(eventName, { tabName })
      return

    default:
      console.log('Posthog: Invalid Custom Event')
  }
}

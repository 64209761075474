import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import type { SupabaseClient } from '@supabase/supabase-js'
import isNil from 'lodash.isnil'
import { getIsCustomer, getIsMerchant, getIsRegistered } from '@centrito/api/services/auth/utils'
import type { User } from '@centrito/api/shared/types'
import type { AppThunkApiConfig } from '@centrito/app/store'
import loadMerchantData from '@centrito/app/store/slices/merchantData/loadMerchantData'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'
import { loadAnonymousCart, loadUserData } from '@centrito/app/store/slices/userData/thunks'
import signOut from './signOut'

interface LoadAuthStateChangeRequestPayload {
  user: User | null
  supabaseClient: SupabaseClient
  initialAuthState?: boolean
}

interface LoadAuthStateChangeResponsePayload {
  user: User
}

const loadAuthStateChange = createAsyncThunk<
  LoadAuthStateChangeResponsePayload,
  LoadAuthStateChangeRequestPayload,
  AppThunkApiConfig
>(`${REDUCER_NAMES.AUTH}/loadAuthStateChange`, async (payload, thunkAPI) => {
  try {
    const { user, supabaseClient } = payload

    if (!isNil(user)) {
      const isRegistered =
        thunkAPI.getState().auth.authenticatedData?.isRegistered ?? getIsRegistered(user)
      const isCustomer = getIsCustomer(user)
      const isMerchant = getIsMerchant(user)

      if (isCustomer && isRegistered) {
        thunkAPI.dispatch(loadUserData())
        thunkAPI.dispatch(loadAnonymousCart())
      } else if (isMerchant) {
        thunkAPI.dispatch(loadMerchantData())
      } else if (!isCustomer && !isMerchant) {
        thunkAPI.dispatch(signOut({ supabaseClient }))
        throw new Error('User has invalid values')
      }

      return {
        user: {
          ...user,
          user_metadata: {
            ...user.user_metadata,
            isRegistered,
          },
        },
      }
    } else {
      throw new Error('User not authenticated')
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(miniSerializeError(error))
  }
})
export default loadAuthStateChange

import isEqual from 'lodash.isequal'
import type { CartItemExtendedPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites/cart-item-extended.composite'
import toCartItemBasic from '@centrito/api/services/shopper/cart/items/utils/toCartItemBasic'

export function removeCartItemLocal<T extends CartItemExtendedPublicComposite>(
  cartItems: T[],
  targetItem: T,
): T[] {
  const targetItemBasic = toCartItemBasic(targetItem)
  return cartItems.filter((item) => !isEqual(targetItemBasic, toCartItemBasic(item)))
}

export function updateCartItemQuantityLocal<T extends CartItemExtendedPublicComposite>(
  cartItems: T[],
  targetItem: T,
  quantityChange: number,
): T[] {
  const targetItemBasic = toCartItemBasic(targetItem)
  return cartItems.map((item) => {
    if (isEqual(targetItemBasic, toCartItemBasic(item))) {
      return {
        ...item,
        cartItem: {
          ...item.cartItem,
          quantity: targetItem.cartItem.quantity + quantityChange,
        },
      }
    }
    return item
  })
}

import posthog from 'posthog-js'
import { ShapedGroup, type ShapedGroupType } from '@centrito/api/shared/catalog'
import { type PosthogClient } from '@centrito/app/utils/services/analytics/posthog'

enum ShapedFlags {
  SHAPED_A = 'web-shaped-a',
  SHAPED_B = 'web-shaped-b',
}

export default function (
  this: PosthogClient,
  isEnabled: boolean = this.analyticsEnabled,
): ShapedGroupType | undefined {
  if (!isEnabled || !this.clientEnabled) return undefined
  const flagValue = posthog?.getFeatureFlag('web-shaped')

  if (flagValue === ShapedFlags.SHAPED_A) {
    return ShapedGroup.enum.A
  } else if (flagValue === ShapedFlags.SHAPED_B) {
    return ShapedGroup.enum.B
  }
  return undefined
}

import type { CustomEventProperties } from '@centrito/app/utils/notifications/shopper/utils/CustomEventProperties'

export default function triggerCustomEvent(
  pushwooshInstance: any,
  eventName: string,
  customEventProperties?: CustomEventProperties,
): void {
  switch (eventName) {
    case 'add_product':
      const product_name = customEventProperties?.product_name ?? ''
      pushwooshInstance.push(function (api: any): void {
        api.postEvent(eventName, {
          product_name: product_name,
        })
      })
      break

    case 'create_order':
      pushwooshInstance.push(function (api: any): void {
        api.postEvent(eventName)
      })
      break

    default:
      console.log('NotificationsClient: Evento personalizado inválido')
  }
}

import { OrderPaymentState } from '@prisma/client/react-native'
import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import type { UserProfile } from '@centrito/db/prisma/generated/zod'
import type { NequiSendPushNotification } from '@centrito/api/router/payments/nequi/sendPushNotification'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'
import { type PostNequiPushNotificationResponse } from '@centrito/app/utils/api/types'

const postNequiPushNotification = createAsyncThunk<
  PostNequiPushNotificationResponse,
  NequiSendPushNotification,
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/postNequiPushNotification`, async (payload, thunkAPI) => {
  try {
    let newUserInfo: UserProfile | undefined

    if (payload.hasFormChanges) {
      const updateNequiResponse = await trpcProxyClient.user.profile.updateNequi.mutate({
        governmentId: payload.governmentId,
        governmentIdType: payload.governmentIdType,
        nequiPhone: payload.phoneNumber,
        legalEntity: payload.personType,
      })
      newUserInfo = updateNequiResponse.newUserInfo
    }

    // eslint-disable-next-line prettier/prettier
    const paymentResponse =
      await trpcProxyClient.payments.nequi.sendPushNotification.mutate(payload)

    if (paymentResponse.paymentStatus !== OrderPaymentState.START_PENDING) {
      return thunkAPI.rejectWithValue(miniSerializeError(new Error('payment failed')))
    }

    return {
      paymentResponse,
      userInfo: newUserInfo,
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(miniSerializeError(error))
  }
})
export default postNequiPushNotification

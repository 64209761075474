import {
  ANALYTICS_ENABLED,
  POSTHOG_ENABLED,
} from '@centrito/app/utils/services/analytics/posthog/utils/constants'
import * as methods from './public'

export class PosthogClient {
  analyticsEnabled = ANALYTICS_ENABLED
  clientEnabled = POSTHOG_ENABLED

  public setIdentity = methods.setIdentity
  public resetIdentity = methods.resetIdentity
  public capturePageView = methods.capturePageView
  public captureCustomEvent = methods.captureCustomEvent
  public optOutCapture = methods.optOutCapture
  public captureProductScreen = methods.captureProductScreen
  public captureProductImpression = methods.captureProductImpression
  public captureProductClick = methods.captureProductClick
  public getShapedGroup = methods.getShapedGroup
}

const posthogClient = new PosthogClient()

export default posthogClient

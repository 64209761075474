import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import { PUSHWOOSH_APP_ID } from '@centrito/app/config/index.web'
import Sentry from '@centrito/app/sentry/shopper'
import * as methods from './public'
import type { CustomEventProperties } from './utils/CustomEventProperties'
import { PUSHWOOSH_EVENTS_ENABLED } from './utils/constants'

class NotificationsClient {
  private isInitialised: boolean
  private Pushwoosh: any
  eventsEnabled = PUSHWOOSH_EVENTS_ENABLED

  constructor() {
    this.isInitialised = false
  }

  public getIsInitialised(): boolean {
    return this.isInitialised
  }

  public initialise(): void {
    this.Pushwoosh = (window as any).Pushwoosh || []
    this.Pushwoosh.push([
      'init',
      {
        logLevel: 'error',
        applicationCode: PUSHWOOSH_APP_ID,
        defaultNotificationTitle: 'SamSam',
        defaultNotificationImage: 'https://samsam.co/icon.png',
        autoSubscribe: true,
        subscribeWidget: {
          enable: false,
        },
      },
    ])
    // Ready Event
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.Pushwoosh.push((api: unknown): void => {
      this.Pushwoosh.subscribe()
      this.isInitialised = true
    })
  }

  public async addReachability(userId: string): Promise<void> {
    this.Pushwoosh.getHWID().then((hwid: string) => {
      trpcProxyClient.user.profile.addHardwareId
        .mutate({ hardwareId: hwid, platform: 'web', userId: userId })
        .catch((error) => {
          Sentry.captureException(error)
        })
    })
  }

  private async removeReachability(userId: string, hwid: string): Promise<void> {
    try {
      await trpcProxyClient.user.profile.removeHardwareId.mutate({
        userId: userId,
        hardwareId: hwid,
        platform: 'web',
      })
    } catch (error) {
      console.error('Error removing reachability:', error)
      Sentry.captureException(error)
      throw error
    }
  }

  public async logout(): Promise<void> {
    try {
      const hwid = await this.Pushwoosh.getHWID()
      const userId = await this.Pushwoosh.getUserId()
      await this.removeReachability(userId, hwid)
      this.setUserId(hwid)
    } catch (error) {
      console.error('Error during NotificationsClient logout:', error)
      throw error
    }
  }

  private setUserId(userId: string): void {
    this.Pushwoosh.push((api: any): void => {
      const response = api.registerUser(userId)
    })
  }

  public login(userId: string): void {
    this.setUserId(userId)
    this.addReachability(userId)
  }

  public addTags(tags: { [key: string]: string }): void {}

  public triggerCustomEvent(
    eventName: string,
    customEventProperties?: CustomEventProperties,
  ): void {
    if (this.isInitialised && this.eventsEnabled) {
      methods.triggerCustomEvent(this.Pushwoosh, eventName, customEventProperties)
    } else if (!this.isInitialised) {
      console.error('NotificationsClient: Pushwoosh not initialised')
    } else {
      console.warn('NotificationsClient: Pushwoosh events not enabled')
    }
  }
}

export const shopperNotificationsClient = new NotificationsClient()

import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'

export interface GetClientUserIdPayload {
  phoneNumber: string
}

export interface GetClientUserIdResponse {
  userId: string | null
}

const getClientUserId = createAsyncThunk<
  GetClientUserIdResponse,
  GetClientUserIdPayload,
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/getClientUserId`, async (payload, thunkAPI) => {
  try {
    const { phoneNumber } = payload
    const clientUser = await trpcProxyClient.auth.salesAssistant.getExternalUserByPhone.query({
      phone: phoneNumber,
    })
    if (!clientUser) {
      // Sales create a user
      const { id: userId } = await trpcProxyClient.auth.salesAssistant.signUpClientByPhone.mutate({
        phone: phoneNumber,
      })
      return { userId }
    }
    return { userId: clientUser?.id }
  } catch (error) {
    console.warn(error)
    return thunkAPI.rejectWithValue(miniSerializeError(error))
  }
})

export default getClientUserId

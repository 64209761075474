import isEqual from 'lodash.isequal'
import isNil from 'lodash.isnil'
import type { User } from '@centrito/api/shared/types'
import { getAuthenticatedData } from '@centrito/app/store/auth'
import type { AuthenticatedData } from '@centrito/app/store/slices/auth/schema'

export const getIsAuthChange = ({
  authChangeUser,
  hasStateInAuthSession,
  stateAuthenticatedData,
}: {
  authChangeUser: User | null
  hasStateInAuthSession: boolean
  stateAuthenticatedData: AuthenticatedData | null
}): boolean => {
  return (
    (hasStateInAuthSession && isNil(authChangeUser)) ||
    (!hasStateInAuthSession && !isNil(authChangeUser)) ||
    (!isNil(authChangeUser) &&
      !isNil(stateAuthenticatedData) &&
      !isEqual(getAuthenticatedData(authChangeUser), stateAuthenticatedData))
  )
}

export const isExpiredAuthLink = (asPath: string) => {
  if (asPath.includes('#error=')) {
    const errorPath = asPath.substring(asPath.indexOf('#error=') + 1)
    const errorData = Object.fromEntries(
      errorPath
        .split('&')
        .map((kv) => kv.split('='))
        .filter((kv) => kv.length === 2),
    )
    if (errorData?.error_description) {
      return errorData.error_description.includes('expired')
    }
  }
  return false
}

export const isAccessTokenAuthLink = (asPath: string) => {
  return asPath.includes('#access_token=')
}

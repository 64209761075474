import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import isNil from 'lodash.isnil'
import isUndefined from 'lodash.isundefined'
import { DiscountDataStatus } from '@centrito/common/constants'
import type { DiscountData } from '@centrito/api/models/public'
import type { CartExtendedPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites/cart-extended.composite'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'

const addCoupon = createAsyncThunk<
  {
    updatedCart: CartExtendedPublicComposite
    isUsingCouponAlready: boolean
  },
  ({ couponId: string; couponCode?: never } | { couponCode: string; couponId?: never }) & {
    isReplacingCoupon?: boolean
  },
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/addCoupon`, async (payload, thunkAPI) => {
  const currentState = thunkAPI.getState()
  const isAuthenticated = !isUndefined(currentState.auth.authenticatedData?.userId)
  if (isAuthenticated) {
    try {
      const resp = await trpcProxyClient.user.cart.addCoupon.mutate(payload)
      const updatedCart = await trpcProxyClient.user.cart.get.query()

      return {
        isUsingCouponAlready: resp.isUsingCouponAlready,
        updatedCart,
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(miniSerializeError(e))
    }
  } else {
    const couponCodeCampaign =
      'couponCode' in payload && payload.couponCode
        ? await trpcProxyClient.promotions.coupons.getCampaignByCode.query({
            couponCode: payload.couponCode,
          })
        : null

    const campaign =
      'couponId' in payload && payload.couponId
        ? await trpcProxyClient.promotions.coupons.getCampaignById.query({
            couponId: payload.couponId,
          })
        : couponCodeCampaign

    if (isNil(campaign)) {
      throw new Error('Either couponId or couponCode must be provided')
    }

    const updatedCart = {
      ...currentState.userData.cart,
      cart: {
        ...currentState.userData.cart.cart,
        discountData: {
          campaigns: [
            {
              id: campaign.id,
              rules: campaign.rules,
              status: DiscountDataStatus.IN_USE,
              internalName: campaign.internalName,
            },
          ],
        } satisfies DiscountData,
      },
    }
    const pricingData = await trpcProxyClient.user.cart.computeCartPricing.mutate(updatedCart)
    return {
      isUsingCouponAlready: false,
      updatedCart: { ...updatedCart, pricingData },
    }
  }
})
export default addCoupon

import { UserRole } from '@prisma/client/react-native'
import { createSlice } from '@reduxjs/toolkit'
import { getAuthenticatedData } from '@centrito/app/store/auth'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'
import posthogClient from '@centrito/app/utils/services/analytics/posthog'
import initialState from './initialState'
import type { AuthState } from './schema'
import * as thunks from './thunks'

const REDUCER_NAME = REDUCER_NAMES.AUTH

const authSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // builder.addCase(
    //   HYDRATE,
    //   (state): AuthState => ({
    //     // No need to pass SSR auth state to client
    //     ...state,
    //   }),
    // )
    builder.addCase(thunks.loadAuthStateChange.fulfilled, (state, { payload }): AuthState => {
      const { user } = payload

      posthogClient.setIdentity(user.id, {
        phone: user.phone,
        email: user.email,
      })

      if (user.user_metadata.role.includes(UserRole.SALES_ASSISTANT)) {
        posthogClient.optOutCapture()
      }

      return {
        ...state,
        authenticatedData: getAuthenticatedData(user),
        authLoaded: true,
      }
    })
    builder.addCase(thunks.devLogin.fulfilled, (): AuthState => {
      return {
        ...initialState,
        authLoaded: true,
      }
    })
    builder.addCase(thunks.loadAuthStateChange.rejected, (): AuthState => {
      return {
        ...initialState,
        authLoaded: true,
      }
    })
    builder.addCase(thunks.signUp.fulfilled, (state): AuthState => {
      if (state.authenticatedData) {
        return {
          ...state,
          authenticatedData: {
            ...state.authenticatedData,
            isRegistered: true,
          },
        }
      }
      return state
    })
    builder.addCase(thunks.signOut.fulfilled, (state): AuthState => {
      return {
        ...state,
        authenticatedData: null,
      }
    })
    builder.addCase(
      thunks.signOut.rejected,
      (state): AuthState => ({
        ...state,
        authenticatedData: null,
      }),
    )
  },
})

export const actions = authSlice.actions
export default authSlice

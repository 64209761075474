import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import isNil from 'lodash.isnil'
import type { CartExtendedPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites/cart-extended.composite'
import toCartItemBasic from '@centrito/api/services/shopper/cart/items/utils/toCartItemBasic'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'

export interface LoadAnonymousCartResponse {
  cart: CartExtendedPublicComposite
}

const loadAnonymousCart = createAsyncThunk<LoadAnonymousCartResponse, void, AppThunkApiConfig>(
  `${REDUCER_NAMES.USER_DATA}/loadAnonymousCart`,
  async (_, thunkAPI) => {
    try {
      const currentCart = thunkAPI.getState().userData.cart
      const anonymousDiscountData = currentCart?.cart.discountData

      if (!isNil(anonymousDiscountData)) {
        await trpcProxyClient.user.cart.addAnonymousCartDiscount.mutate({
          anonymousDiscountData,
        })
      }

      if ((currentCart?.items ?? []).length > 0) {
        const anonymousCartItems = (currentCart?.items ?? []).map((item) => toCartItemBasic(item))
        const updatedCart = await trpcProxyClient.user.cart.replaceAndGetCartOnAuthLoaded.mutate({
          anonymousCartItems,
        })
        const pricingData = await trpcProxyClient.user.cart.computeCartPricing.mutate(updatedCart)
        return {
          cart: { ...updatedCart, pricingData },
        }
      }

      const cart = await trpcProxyClient.user.cart.get.query()
      const pricingData = await trpcProxyClient.user.cart.computeCartPricing.mutate(cart)
      return {
        cart: { ...cart, pricingData },
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(miniSerializeError(error))
    }
  },
)
export default loadAnonymousCart

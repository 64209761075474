import { YStack, styled } from '@centrito/ui/src'
import { MOBILE_WIDTH_STYLES } from '@centrito/ui/src/constants'

const NavBarLayoutPage = styled(YStack, {
  ...MOBILE_WIDTH_STYLES,
  margin: 0,
  height: '100%',
  overflow: 'hidden',
  justifyContent: 'center',
  className: 'catalog-page',
  backgroundColor: '#FFFFFF',
})

export default NavBarLayoutPage
